<template>
  <div class="products">
    <div class="three-btns container mb-4">
      <button @click="$router.push('/')">{{ $t("Main") }} /</button>
      <button>{{ $t("Offers") }} /</button>
      <button>{{ $getByLang(one.name) }}</button>
    </div>
    <div class="change-home position-relative mb-5">
      <div class="container d-flex justify-content-around flex-wrap">
        <p class="mt-3 mb-5">
          {{ $getByLang(one.name)}}
          <!-- <span> {{ $t("end") }} {{ $formatDateOffers(one.toDate) }} </!--> 
          <span class="d-block">
            <span>{{ $t("with") }}</span>
            {{ $t("home her") }}
          </span>
        </p>
        <!-- <img
          class="mb-5"
          :src="$baseUploadURL + one.offerImage"
          :alt="$getByLang(one.name)"
          style="max-width: 313px"
        /> -->
      </div>
      <!-- <div class="background position-absolute bacj"></div> -->
    </div>

    <!-- categories and recently arrived -->
    <div class="categories mt-11 mt-5 px-4">
      <div class="row">
        <div class="col-md-3 col-xs-12">
          <button
            @click="() => (showFilter = !showFilter)"
            class="p-2 rounded d-lg-none d-flex align-items-center gap-2"
          >
            <span class="pi pi-filter"></span>
            <!-- <span>Filter</span> -->
          </button>
          <div class="sidebarsearch mt-2 p-3" v-if="showFilter">
            <div class="mb-3">
              <h3>{{ $t("Categories") }}</h3>
              <div
                class="field-checkbox mb-2"
                v-for="item of categoriesList"
                :key="'categories_' + item._id"
              >
                <Checkbox
                  :id="'categories_' + item._id"
                  name="categoriesId"
                  :value="item._id"
                  v-model="body.categoriesId"
                  :inputId="'categories_' + item._id"
                />
                <label :for="'categories_' + item._id">{{
                  $getByLang(item.name)
                }}</label>
              </div>
            </div>
            <div class="mb-3">
              <h3>{{ $t("sections") }}</h3>
              <div
                class="field-checkbox mb-2"
                v-for="item of subcategoriesList"
                :key="'subcategories_' + item._id"
              >
                <Checkbox
                  :id="'subcategories_' + item._id"
                  name="subCategoriesId"
                  :value="item._id"
                  v-model="body.subCategoriesId"
                  :inputId="'subcategories_' + item._id"
                />
                <label :for="'subcategories_' + item._id">{{
                  $getByLang(item.name)
                }}</label>
              </div>
            </div>

            <div class="mb-3">
              <h3>{{ $t("the size") }}</h3>
              <div
                class="field-checkbox mb-2"
                v-for="item of sizeList"
                :key="'size_' + item._id"
              >
                <Checkbox
                  :id="'size_' + item._id"
                  name="sizeId"
                  :value="item._id"
                  v-model="body.sizes.sizeId"
                  :inputId="'size_' + item._id"
                />
                <label :for="'size_' + item._id">
                  {{ item.width }}W X {{ item.height }}H CM
                </label>
              </div>
            </div>

            <div class="mb-3">
              <h3>{{ $t("Colors") }}</h3>

              <span v-for="item of colorsList" :key="'colors_' + item._id">
                <input
                  type="radio"
                  class="btn-check"
                  name="colorId"
                  :id="'colors_' + item._id"
                  autocomplete="off"
                  :value="item._id"
                  v-model="body.colorsList.colorId"
                />
                <label
                  class="btn btn-color"
                  :for="'colors_' + item._id"
                  :style="'border-color: ' + item.value + ' !important'"
                >
                  <svg
                    width="23"
                    height="23"
                    viewBox="0 0 23 23"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle cx="11.5" cy="11.5" r="11.5" :fill="item.value" />
                  </svg>
                </label>
              </span>
            </div>
            <div class="mb-3">
              <h3>{{ $t("the price") }}</h3>
              <div class="row mb-4">
                <div class="col-md-6">{{ body.price[1] }}{{ $t("SAR") }}</div>
                <div class="col-md-6 text-left">
                  {{ body.price[0] }}{{ $t("SAR") }}
                </div>
              </div>
              <div>
                <Slider
                  v-model="body.price"
                  :min="0"
                  :max="100000"
                  :range="true"
                />
              </div>
            </div>

            <div class="mb-3">
              <h3>{{ $t("resources") }}</h3>
              <div
                class="field-checkbox mb-2"
                v-for="item of materialsList"
                :key="'materials_' + item._id"
              >
                <Checkbox
                  :id="'materials_' + item._id"
                  name="materialsId"
                  :value="item._id"
                  v-model="body.materialsId"
                  :inputId="'materials_' + item._id"
                />
                <label :for="'materials_' + item._id">
                  {{ $getByLang(item.name) }}
                </label>
              </div>
            </div>

            <div class="mb-3">
              <h3>{{ $t("the shop") }}</h3>
              <div
                class="field-checkbox mb-2"
                v-for="item of vendorsList"
                :key="'vendors_' + item._id"
              >
                <Checkbox
                  :id="'vendors_' + item._id"
                  name="vendorsId"
                  :value="item._id"
                  v-model="body.vendorsId"
                  :inputId="'vendors_' + item._id"
                />
                <label :for="'vendors_' + item._id">
                  {{ item.name }}
                </label>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-9 col-xs-12">
          <div class="new m-2">
            <h1 class="cat-head mb-4">{{ $t("offer details") }}</h1>
            <div style="min-height: 700px">
              <div class="m-5 text-center" v-if="list.length == 0">
                <img
                  src="../../assets/images/notFoundIllustration.svg"
                  class="mt-5"
                  alt=""
                  style="max-width: 300px"
                />
              </div>
              <div class="row"  v-if="filteredData.length > 0">
                <div class="col-md-4 mb-2 p-3" v-for="item in filteredData" :key="item._id">
                  <div
                    class="newCard"
                    
                    @click="navigateToItem(item)"
                  >
                    <span class="itemofferdbyvalue">
                      {{ $t("rival") }}
                      {{ item.offerValue }}%
                    </span>

                    <img
                      class="logo"
                      :src="
                        item.itemObj.colorsList.length > 0 &&
                        item.itemObj.colorsList[0].colorsImage.length > 0
                          ? $baseUploadURL +
                            item.itemObj.colorsList[0].colorsImage[0].name
                          : ''
                      "
                      :alt="$getByLang(item.itemName)"
                    />

                    <div class="newCard-body text-truncate">
                      <h5>{{$textSlice($getByLang(item.itemName), 40) }}</h5>
                      <b-form-rating
                        v-model="item.rate"
                        variant="warning"
                        class="mb-2"
                        readonly
                        inline
                        no-border
                        size="sm"
                        locale="ar-EG"
                      ></b-form-rating>
                      <span> ({{ item.itemObj.rateAmount }}) </span>

                      <p>
                        {{
                          (item.priceBeforeOffer -
                          item.priceBeforeOffer * (item.offerValue / 100)).toFixed(2)
                        }}
                        {{ $t("r.s") }}
                        <del class="offeroldprice">
                          {{ item.priceBeforeOffer }}
                          {{ $t("r.s") }}
                        </del>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- <Paginator  :pageNumber="pageNum"
              v-if="list.length > 0"
              style="direction: ltr"
              :rows="limit"
              :totalRecords="totalItemsCount"
              @page="onPage($event)"
            ></Paginator> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
        pageNum:
        sessionStorage.homeHerePageOffer == "offers"
          ? parseInt(sessionStorage.homeHerePageNumberOffer)
          : 1,
          selectedItemsFromOffer:[],
      body: {
        categoriesId: [],
        subCategoriesId: [],
        colorsList: {
          colorId: null
        },
        sizes: {
          sizeId: []
        },
        price: [15000, 77000],
        vendorsId: [],
        materialsId: []
      },
      categoriesList: [],
      subcategoriesList: [],
      sizeList: [],
      colorsList: [],
      vendorsList: [],

      materialsList: [],
      list: [],
      totalItemsCount: 0,

      limit: 0,
      page: 1,
      id: null,
      one: {
        name: {
          ar: null,
          en: null
        },
        bannerSubCategoryImage: null,
        value: 0
      },

      showFilter: false
    };
  },
  mounted() {
    if (window.innerWidth > 992) {
      this.showFilter = true;
    }
  },
  methods: {
    navigateToItem(data){
localStorage.setItem("sizeId",data.sizeId._id)
this.$router.push('/product/'+data.itemId);
    },
    $goToSave(link) {
      sessionStorage.setItem("homeHerePageOffer", "offers");
      sessionStorage.setItem("homeHerePageNumberOffer", this.pageNum);
      this.$router.push(link);
    },
    addLike(item) {
      if (localStorage.homeHere) {
        this.$http
          .post("favourite", {
            itemId: item._id
          })
          .then((res) => {
            item.imLikedThis = !item.imLikedThis;
            this.$swal({
              title: this.$t("Successfully Done"),
              icon: "success",
              timer: 3000,
              showConfirmButton: false
            });
          });
      } else {
        this.$toast.add({
          severity: "error",
          summary: this.$t("Error"),
          detail: "Must be loged in",
          life: 3000
        });
      }
    },
    onPage(event) {
     this.pageNum = event.page + 1;
      this.getData(event.page + 1);
      sessionStorage.setItem("homeHerePageOffer", "offers");
      sessionStorage.setItem("homeHerePageNumberOffer", this.pageNum);
    
    },
    
    getData(page = 1) {
      let obj = {};
      if (this.body.id.length > 0) {
        obj._id = { $in: this.body.id };
      }

      if (this.body.categoriesId.length > 0) {
        obj.categoriesId = { $in: this.body.categoriesId };
      }
      if (this.body.subCategoriesId.length > 0) {
        obj.subCategoriesId = { $in: this.body.subCategoriesId };
      }
      if (this.body.vendorsId && this.body.vendorsId.length > 0) {
        obj.vendorsId = { $in: this.body.vendorsId };
      }
      if (this.body.materialsId.length > 0) {
        obj.materialsId = { $in: this.body.materialsId };
      }
      if (this.body.sizes.sizeId.length > 0) {
        obj.sizes = {
          sizeId: { $in: this.body.sizes.sizeId }
        };
      }
      if (this.body.colorsList.colorId) {
        obj.colorsList = {
          colorId: this.body.colorsList.colorId
        };
      }

      if (this.body.price[0] != 15000 && this.body.price[1] != 77000) {
        obj.sizes.mainPrice = {
          $gte: this.body.price[0],
          $lte: this.body.price[1]
        };
      }
      this.$http.post(`items/search?limit=12&page=${this.pageNum}`, obj).then((res) => {
        
        this.list = res.data.docs;
        this.totalItemsCount = res.data.totalDocs;
        this.limit = res.data.limit;
      });
    }
  },
  created() {
    this.id = this.$route.params.id;

    this.$http.get("offers/" + this.id).then((res) => {
      this.one = res.data;
    });

    this.$http
      .post("offers/getOfferDetailsById/", {
        offerId: this.id
      })
      .then((res) => {
        let list = res.data.docs;
        this.selectedItemsFromOffer = res.data.docs;
        console.log("1111111111111111111111111111", list);
        
        this.body.id = [...list.map((x) => x.itemId)];
        this.getData();
      });

    this.$http.get("categories?limit=1000000").then((res) => {
      this.categoriesList = res.data.docs;
    });
    this.$http.get("subcategories?limit=1000000").then((res) => {
      this.subcategoriesList = res.data.docs;
    });
    this.$http.get("sizes?limit=1000000").then((res) => {
      this.sizeList = res.data.docs;
    });
    this.$http.get("vendors?limit=1000000").then((res) => {
      this.vendorsList = res.data.docs;
    });

    this.$http.get("colors?limit=1000000").then((res) => {
      this.colorsList = res.data.docs;
    });
    this.$http.get("materials?limit=1000000").then((res) => {
      this.materialsList = res.data.docs;
    });
  },
 computed: {
  filteredData() {
    const itemMap = {};

    this.selectedItemsFromOffer.forEach(item => {
      if (!itemMap[item.itemId]) {
        itemMap[item.itemId] = item;
      }
    });

    return Object.values(itemMap);
  }
}
,

  watch: {
    "$route.params.id"(val) {
      this.id = val;

      this.$http.get("offers/" + this.id).then((res) => {
        
        
        this.one = res.data;
      });

      this.$http
        .post("offers/getOfferDetailsById/", {
          offerId: this.id
        })
        .then((res) => {
          const list = res.data.docs;
          this.body.id = [...list.map((x) => x.itemId)];
          this.getData();
        });
    },
    "body.categoriesId"(val) {
      if (val) {
        setTimeout(() => this.getData(), 100);
      }
    },
    "body.subCategoriesId"(val) {
      if (val) {
        setTimeout(() => this.getData(), 100);
      }
    },
    "body.vendorsId"(val) {
      if (val) {
        setTimeout(() => this.getData(), 100);
      }
    },
    "body.materialsId"(val) {
      if (val) {
        setTimeout(() => this.getData(), 100);
      }
    },

    "body.sizes.sizeId"(val) {
      if (val) {
        setTimeout(() => this.getData(), 100);
      }
    },

    "body.colorsList.colorId"(val) {
      if (val) {
        setTimeout(() => this.getData(), 100);
      }
    },
    "this.body.price"(val) {
      if (val) {
        setTimeout(() => this.getData(), 100);
      }
    }
  }
};
</script>

<style></style>
